/* eslint react/destructuring-assignment: 0 */
import styled from "@emotion/styled";
import { graphql, navigate } from "gatsby";
import React, { useContext, useLayoutEffect, useState } from "react";
import { use100vh } from "react-div-100vh";
import Footer from "../components/Footer";
import { LocaleContext } from "../components/Layout";
import MobileLayout from "../components/Sliders/templates/Mobile";

const Hero = styled.header`
  background-color: ${(props) => props.theme.colors.greyLight};
  padding-top: 1rem;
  padding-bottom: 4rem;
`;

const Headline = styled.p`
  color: ${(props) => props.theme.colors.grey};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`;

const PageNavigation = styled.ul`
  position: fixed;
  top: 30%;
  right: 24px;
  list-style: none;
  line-height: 0.6;
  z-index: 9;
`;

const pageTitle = styled.h1`
  font-size: "1.2rem";
`;

const Post = ({
  data: { prismicPost, slides },
  location,
  pageContext: { id, locale },
}) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const heightMobile = use100vh();

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      });

      if (window.innerWidth > 900) {
        i18n && i18n.path === "en" ? navigate("/en") : navigate("/");
      }
    }
  });

  const pageData =
    slides.edges[0].node &&
    slides.edges[0].node.data.body &&
    slides.edges[0].node.data.body;

  const fillRange = (count) =>
    Array(count)
      .fill()
      .map((item, i) => (
        <li>
          <a href={`#${i + 1}`}>{i + 1}</a>
        </li>
      ));

  const centerContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    /*  alignItems: "center", */
    height: `calc(${heightMobile}px - 65px`,
    backgroundColor:
      pageData[0] &&
      pageData[0].items[0] &&
      pageData[0].items[0].background_color,
  };

  return (
    <>
      <main
        style={
          (slides.edges[0].node &&
            slides.edges[0].node.slugs[0] === "phase-deux") ||
          (slides.edges[0].node &&
            slides.edges[0].node.slugs[0] === "phase-two")
            ? centerContent
            : null
        }
        className={`page-${slides.edges[0].node &&
          slides.edges[0].node.slugs[0]}`}
      >
        <div>
          {/* <pageTitle> {slides.edges[0].node.data.title.text}</pageTitle> */}

          {/*      <PageNavigation>{fillRange(pageData.length - 1)}</PageNavigation> */}
          {pageData.map((d, index) => {
            return <MobileLayout key={d.id} node={d} index={index} />;
          })}
        </div>
      </main>
      {slides.edges[0].node &&
        slides.edges[0].node.slugs[0] !== "phase-deux" && <Footer />}
    </>
  );
};

export default Post;

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($id: String!, $locale: String!) {
    slides: allPrismicSlide(
      filter: { id: { eq: $id }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          slugs
          data {
            title {
              text
            }

            body {
              ... on PrismicSlideBodyPage {
                id
                items {
                  padding_image
                  surlignage
                  image_animation_select
                  texte_animation_select
                  image_animation_duration
                  image_animation_delay
                  text_animation_duration
                  text_animation_delay
                  text_only_on_mobile
                  background_color
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    mobile {
                      alt
                      dimensions {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 899) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }

                  text_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    mobile {
                      alt
                      dimensions {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 899) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }

                  extra_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    mobile {
                      alt
                      dimensions {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 899) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                  textarea_color
                  textarea {
                    html
                    raw {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
