import { motion } from "framer-motion";
import Img from "gatsby-image";
import React, { useEffect } from "react";
import { InView } from "react-intersection-observer";

const MobileLayout = ({ node }) => {
  const colLeft = node && node.items && node.items[0] && node.items[0];
  const colRight = node && node.items && node.items[1] && node.items[1];

  const imgLeftValidation =
    colLeft &&
    colLeft.image &&
    colLeft.image.localFile &&
    colLeft.image.localFile.childImageSharp;

  const imgRightValidation =
    colRight &&
    colRight.image &&
    colRight.image.localFile &&
    colRight.image.localFile.childImageSharp;

  const imgTextLeftValidation =
    colLeft &&
    colLeft.text_image &&
    colLeft.text_image.localFile &&
    colLeft.text_image.localFile.childImageSharp;

  const imgTextRightValidation =
    colRight &&
    colRight.text_image &&
    colRight.text_image.localFile &&
    colRight.text_image.localFile.childImageSharp;

  const imgExtratLeftValidation =
    colLeft &&
    colLeft.extra_image &&
    colLeft.extra_image.localFile &&
    colLeft.extra_image.localFile.childImageSharp;

  const imgExtraRightValidation =
    colRight &&
    colRight.extra_image &&
    colRight.extra_image.localFile &&
    colRight.extra_image.localFile.childImageSharp;

  const imgLeftValidationMobile =
    colLeft &&
    colLeft.image &&
    colLeft.image.mobile &&
    colLeft.image.mobile.localFile &&
    colLeft.image.mobile.localFile.childImageSharp;

  const imgTextLeftValidationMobile =
    colLeft &&
    colLeft.text_image.mobile &&
    colLeft.text_image.mobile.localFile &&
    colLeft.text_image.mobile.localFile.childImageSharp;

  const imgTextRightValidationMobile =
    colRight &&
    colRight.text_image.mobile &&
    colRight.text_image.mobile.localFile &&
    colRight.text_image.mobile.localFile.childImageSharp;

  const imgExtraLeftValidationMobile =
    colLeft &&
    colLeft.extra_image.mobile &&
    colLeft.extra_image.mobile.localFile &&
    colLeft.extra_image.mobile.localFile.childImageSharp;

  const imgExtraRightValidationMobile =
    colRight &&
    colRight.extra_image.mobile &&
    colRight.extra_image.mobile.localFile &&
    colRight.extra_image.mobile.localFile.childImageSharp;

  const imgRigthValidationMobile =
    colRight &&
    colRight.image &&
    colRight.image.mobile &&
    colRight.image.mobile.localFile &&
    colRight.image.mobile.localFile.childImageSharp;

  const altImageLeft =
    colLeft &&
    colLeft.image &&
    colLeft.image.mobile &&
    colLeft.image.mobile.alt;

  const altImageRight =
    colRight &&
    colRight.image &&
    colRight.image.mobile &&
    colRight.image.mobile.alt;

  const altTextImageLeft =
    colLeft &&
    colLeft.text_image &&
    colLeft.text_image.mobile &&
    colLeft.text_image.mobile.alt;

  const altExtraImageLeft =
    colLeft &&
    colLeft.extra_image &&
    colLeft.extra_image.mobile &&
    colLeft.extra_image.mobile.alt;

  const altTextImageRight =
    colRight &&
    colRight.text_image &&
    colRight.text_image.mobile &&
    colRight.text_image.mobile.alt;
  const altExtraImageRight =
    colRight &&
    colRight.extra_image &&
    colRight.extra_image.mobile &&
    colRight.extra_image.mobile.alt;

  const imageAnimationOptionLeft = colLeft && colLeft.image_animation_select;
  const imageAnimationOptionRight = colLeft && colLeft.image_animation_select;

  const imageAnimationDurationLeft =
    colLeft && colLeft.image_animation_duration;
  const imageAnimationDelayLeft = colLeft && colLeft.image_animation_delay;

  const textAnimationOptionLeft = colLeft && colLeft.texte_animation_select;
  const textAnimationOptionRight = colRight && colRight.texte_animation_select;

  const textAnimationDurationLeft = colLeft && colLeft.text_animation_duration;
  const textAnimationDurationRight =
    colRight && colRight.text_animation_duration;
  const textAnimationDelayLeft = colLeft && colLeft.text_animation_delay;
  const textAnimationDelayRight = colRight && colRight.text_animation_delay;

  const extraAnimationOptionLeft = colLeft && colLeft.extra_animation_select;
  const extraAnimationOptionRight = colRight && colRight.extra_animation_select;
  const extraAnimationDurationLeft =
    colLeft && colLeft.extra_animation_duration;
  const extraAnimationDurationRight =
    colRight && colRight.extra_animation_duration;
  const extraAnimationDelayLeft = colLeft && colLeft.extra_animation_delay;
  const extraAnimationDelayRight = colRight && colRight.extra_animation_delay;

  const textAreaLeft = colLeft && colLeft.textarea && colLeft.textarea;
  const textAreaRight = colRight && colRight.textarea && colRight.textarea;

  const textAreaLeftRaw =
    colLeft &&
    colLeft.textarea &&
    colLeft.textarea.raw &&
    colLeft.textarea.raw[0] &&
    colLeft.textarea.raw[0].text &&
    colLeft.textarea.raw[0].text;

  const textAreaRightRaw =
    colRight &&
    colRight.textarea &&
    colRight.textarea.raw &&
    colRight.textarea.raw[0] &&
    colRight.textarea.raw[0].text &&
    colRight.textarea.raw[0].text;

  const surlignageColorLeft =
    colLeft && colLeft.surlignage && colLeft.surlignage;
  const surlignageColorRight =
    colRight && colRight.surlignage && colRight.surlignage;

  const textAreaColorLeft =
    colLeft && colLeft.textarea_color && colLeft.textarea_color;
  const textAreaColorRight =
    colRight && colRight.textarea_color && colRight.textarea_color;

  function animationSwitcher(columnValue) {
    switch (columnValue) {
      case "Left":
        return animationLeft;
      case "Right":
        return animationRight;
      case "Fade in":
        return animationFadeIn;
      case "Top":
        return animationTop;
      case "Bottom":
        return animationBottom;
      case "None":
        return "";
      default:
        return animationLeft;
    }
  }

  const animationLeft = {
    initial: { opacity: 0, x: -15 },
    start: { opacity: 1, x: 0 },
  };

  const animationRight = {
    initial: { opacity: 0, x: -15 },
    start: { opacity: 1, x: 0 },
  };

  const animationFadeIn = {
    initial: { opacity: 0 },
    start: { opacity: 1 },
  };

  const animationTop = {
    initial: { opacity: 0, y: -10 },
    start: { opacity: 1, y: 0 },
  };

  const animationBottom = {
    initial: { opacity: 0, y: 10 },
    start: { opacity: 1, y: 0 },
  };

  const easing = "easeOut";
  const threshold = 0;

  useEffect(() => {
    const surlignageLeft = document.querySelectorAll(
      ".text__mobile.left .surlignage"
    );
    const surlignageRight = document.querySelectorAll(
      ".text__mobile.right .surlignage"
    );

    surlignageLeft &&
      Array.from(surlignageLeft).map((s) => {
        s.style.color = String(surlignageColorLeft);
      });

    surlignageRight &&
      Array.from(surlignageRight).map((s) => {
        s.style.color = String(surlignageColorRight);
      });
  }, [surlignageColorLeft, surlignageColorRight]);

  return (
    <>
      <article className="mobile-block-container">
        <section
          className="image-stack-container-left"
          style={{ backgroundColor: colLeft && colLeft.background_color }}
        >
          {/* Image 1 Left */}
          {imgLeftValidation && altImageLeft !== "null" && (
            <InView threshold={threshold}>
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  variants={animationSwitcher(imageAnimationOptionLeft)}
                  animate={inView && "start"}
                  initial="initial"
                  transition={{
                    duration: imageAnimationDurationLeft
                      ? parseInt(imageAnimationDurationLeft)
                      : 1.4,
                    delay: imageAnimationDelayLeft
                      ? parseInt(imageAnimationDelayLeft)
                      : 0.6,
                    ease: easing,
                  }}
                  style={{
                    zIndex: 1,
                  }}
                >
                  {imgLeftValidationMobile ? (
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      objectFit="contain"
                      loading="lazy"
                      fadeIn={false}
                      fluid={
                        node.items[0].image.mobile.localFile.childImageSharp
                          .fluid
                      }
                    />
                  ) : (
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      objectFit="contain"
                      loading="lazy"
                      fadeIn={false}
                      fluid={
                        node.items[0].image.localFile.childImageSharp.fluid
                      }
                      alt={altImageLeft}
                    />
                  )}
                </motion.div>
              )}
            </InView>
          )}

          {/* Text image 1 Left */}
          {imgTextLeftValidation && altTextImageLeft !== "null" && (
            <InView threshold={threshold}>
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  variants={animationSwitcher(textAnimationOptionLeft)}
                  animate={inView && "start"}
                  initial="initial"
                  className="image-mobile"
                  transition={{
                    duration: textAnimationDurationLeft
                      ? parseInt(textAnimationDurationLeft)
                      : 1.4,
                    delay: textAnimationDelayLeft
                      ? parseInt(textAnimationDelayLeft)
                      : 0.6,
                    ease: easing,
                  }}
                  style={{ zIndex: 2 }}
                >
                  {imgTextLeftValidationMobile ? (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgTextLeftValidationMobile.fluid}
                    />
                  ) : (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgTextLeftValidation.fluid}
                      alt={altTextImageLeft}
                    />
                  )}
                </motion.div>
              )}
            </InView>
          )}

          {/* Text Extra image 1 Left */}
          {imgExtratLeftValidation && altExtraImageLeft !== "null" && (
            <InView threshold={threshold}>
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  variants={animationSwitcher(extraAnimationOptionLeft)}
                  animate={inView && "start"}
                  className="image-mobile"
                  initial="initial"
                  transition={{
                    duration: extraAnimationDurationLeft
                      ? parseInt(extraAnimationDurationLeft)
                      : 1.4,
                    delay: extraAnimationDelayLeft
                      ? parseInt(extraAnimationDelayLeft)
                      : 0.6,
                    ease: easing,
                  }}
                  className="image-mobile"
                  style={{ zIndex: 3 }}
                >
                  {imgExtraLeftValidationMobile ? (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgExtraLeftValidationMobile.fluid}
                    />
                  ) : (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgExtratLeftValidation.fluid}
                      alt={altExtraImageLeft}
                    />
                  )}
                </motion.div>
              )}
            </InView>
          )}
        </section>
        {/* Text  Left */}
      </article>
      <section style={{ marginTop: "-1px" }}>
        {textAreaLeft && textAreaLeft.html && textAreaLeft.html.length > 9 && (
          <InView threshold={threshold}>
            {({ inView, ref }) => (
              <motion.div
                ref={ref}
                className="text__mobile left"
                style={{
                  backgroundColor: colLeft && colLeft.background_color,
                  color: textAreaColorLeft,
                }}
                /*  variants={variants} */
                initial="hidden"
                animate={inView && "visible"}
                dangerouslySetInnerHTML={{ __html: textAreaLeft.html }}
              />
            )}
          </InView>
        )}
      </section>
      <article className="mobile-block-container" style={{ marginTop: "-1px" }}>
        <div
          className="image-stack-container-right"
          style={{
            backgroundColor: colLeft && colLeft.background_color,
            marginTop: "-1px",
          }}
        >
          {/* Image 1 Right */}
          {imgRightValidation && altImageRight !== "null" && (
            <InView threshold={threshold}>
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  variants={animationSwitcher(imageAnimationOptionRight)}
                  animate={inView && "start"}
                  initial="initial"
                  transition={{
                    duration: imageAnimationDurationLeft
                      ? parseInt(imageAnimationDurationLeft)
                      : 1.4,
                    delay: imageAnimationDelayLeft
                      ? parseInt(imageAnimationDelayLeft)
                      : 0.6,
                    ease: easing,
                  }}
                  style={{
                    backgroundColor: colRight && colRight.background_color,
                    zIndex: 1,
                  }}
                >
                  {imgRigthValidationMobile ? (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={
                        node.items[1].image.mobile.localFile.childImageSharp
                          .fluid
                      }
                      alt={altImageRight}
                    />
                  ) : (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={
                        node.items[1].image.localFile.childImageSharp.fluid
                      }
                      alt={altImageRight}
                    />
                  )}
                </motion.div>
              )}
            </InView>
          )}

          {/* Text image 1 Right */}
          {imgTextRightValidation && altTextImageRight !== "null" && (
            <InView threshold={threshold}>
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  variants={animationSwitcher(textAnimationOptionRight)}
                  animate={inView && "start"}
                  className="image-mobile"
                  initial="initial"
                  transition={{
                    duration: textAnimationDurationRight
                      ? parseInt(textAnimationDurationRight)
                      : 1.4,
                    delay: textAnimationDelayRight
                      ? parseInt(textAnimationDelayRight)
                      : 0.6,
                    ease: easing,
                  }}
                  style={{ zIndex: 2 }}
                >
                  {imgTextRightValidationMobile ? (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgTextRightValidationMobile.fluid}
                      alt={altTextImageRight}
                    />
                  ) : (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgTextRightValidation.fluid}
                      alt={altTextImageRight}
                    />
                  )}
                </motion.div>
              )}
            </InView>
          )}

          {/* Text Extra image 1 Right */}
          {imgExtraRightValidation && altExtraImageRight !== "null" && (
            <InView threshold={threshold}>
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  variants={animationSwitcher(extraAnimationOptionRight)}
                  animate={inView && "start"}
                  className="image-mobile"
                  initial="initial"
                  transition={{
                    duration: extraAnimationDurationRight
                      ? parseInt(extraAnimationDurationRight)
                      : 1.4,
                    delay: extraAnimationDelayRight
                      ? parseInt(extraAnimationDelayRight)
                      : 0.6,
                    ease: easing,
                  }}
                  className="image-mobile"
                  style={{ zIndex: 3 }}
                >
                  {imgExtraRightValidationMobile ? (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgExtraRightValidationMobile.fluid}
                      alt={altExtraImageRight}
                    />
                  ) : (
                    <Img
                      imgStyle={{
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      loading="lazy"
                      fadeIn={false}
                      fluid={imgExtraRightValidation.fluid}
                      alt={altExtraImageRight}
                    />
                  )}
                </motion.div>
              )}
            </InView>
          )}
        </div>
      </article>

      <section>
        {/* Text Right */}
        {textAreaRight && textAreaRight.html && textAreaRight.html.length > 9 && (
          <InView threshold={threshold}>
            {({ inView, ref }) => (
              <motion.div
                ref={ref}
                className="text__mobile right"
                /*  variants={variants} */
                initial="hidden"
                animate={inView && "visible"}
                style={{
                  backgroundColor: colRight && colRight.background_color,
                  color: textAreaColorRight,
                  padding: "1rem",
                }}
                dangerouslySetInnerHTML={{ __html: textAreaRight.html }}
              />
            )}
          </InView>
        )}
      </section>
    </>
  );
};

export default MobileLayout;
